import { IconChevronRight, IconSearch } from '@tabler/icons-react';
import MainArticles from '../components/MainArticles';
import { useNavigate } from 'react-router';
import { useEffect } from 'react';
import AchievementsBlock from '../components/AchievementsBlock';
import PartnersBlock from '../components/PartnersBlock';
import { Link } from 'react-router-dom';
const MainPage = () => {
    const navigate = useNavigate();

    useEffect(() => {
        var input = document.getElementById("searchBoxMain");

        input.addEventListener("keypress", function (event) {

            if (event.key === "Enter") {
                event.preventDefault();
                navigate(`/szukaj/${input.value}`);
            }
        });
    }, []);

    return (
        <main className="flex flex-col md:flex-row justify-evenly w-full md:px-3 mt-5">
            <Link to={'/15-podatku'} className="highContrastBackground p-5 mt-3 mx-3 cursor-pointer hover:dark:bg-blue-900 hover:bg-blue-700 transition items-center justify-between text-center bg-blue-900 dark:bg-blue-950 text-white rounded-lg md:hidden flex shadow-lg border-2 dark:border-blue-900 border-blue-700">
                <div className="flex gap-3">
                    <img src="/assets/poltora_podatku.png" alt="1,5% podatku" className="w-12" />
                    <p className="font-semibold">1,5% podatku</p>
                </div>
                <IconChevronRight />
            </Link>

            <div
                className="highContrastBackground flex md:hidden flex-col text-center bg-blue-900 dark:bg-blue-950 text-white p-5 mt-3 mx-3 rounded-lg">
                <p className="font-semibold text-center text-xl mb-6">Kontakt</p>
                <div className="flex flex-col">
                    <p className="text-xl mb-5">Zespół Szkół Energetycznych</p>
                    <p>ul. Loretańska 16, 31-114 Kraków</p>
                    <p>tel./fax.: <a href="tel:124222187"
                        className="hover:bg-blue-700 py-0.5 px-2 rounded-lg transition">12 422 21 87</a></p>
                    <p>tel.: <a href="tel:124222947" className="hover:bg-blue-700 py-0.5 px-2 rounded-lg transition">12
                        422 29 47</a></p>
                    <p>Email: <a href="mailto:sekretariat@zse.krakow.pl"
                        className="hover:bg-blue-700 py-0.5 px-2 rounded-lg transition">sekretariat@zse.krakow.pl</a>
                    </p>
                </div>
            </div>

            <div className="order-2 flex flex-col gap-5 md:max-w-lg my-5 px-3 flex-shrink">
                <div className="highContrastBackground flex items-center bg-slate-100 border-slate-200 dark:bg-gray-800 dark:border-gray-700 dark:text-white px-4 py-2.5 rounded-lg gap-3 border">
                    <IconSearch size="20" />
                    <input id="searchBoxMain" type="text" placeholder="Szukaj na stronie..." className="w-full outline-none border-none bg-transparent rounded-lg" />
                </div>
                <Link to={'/15-podatku'} className="highContrastBackground cursor-pointer hover:dark:bg-blue-900 hover:bg-blue-700 transition items-center justify-between text-center bg-blue-900 dark:bg-blue-950 text-white p-5 rounded-lg hidden md:flex shadow-lg border-2 dark:border-blue-900 border-blue-700">
                    <div className="flex gap-3">
                        <img src="/assets/poltora_podatku.png" alt="1,5% podatku" className="w-12" />
                        <p className="font-semibold">1,5% podatku</p>
                    </div>
                    <IconChevronRight />
                </Link>

                <div className="highContrastBackground flex-col text-center bg-blue-900 dark:bg-blue-950 text-white p-5 md:rounded-lg hidden md:flex shadow-lg">
                    <p className="font-semibold text-center text-xl mb-6">Kontakt</p>
                    <div className="flex flex-col">
                        <p className="text-xl mb-5">Zespół Szkół Energetycznych</p>
                        <p>ul. Loretańska 16, 31-114 Kraków</p>
                        <p>tel./fax.: <a href="tel:124222187"
                            className="hover:bg-blue-700 py-0.5 px-2 rounded-lg transition">12 422 21 87</a></p>
                        <p>tel.: <a href="tel:124222947"
                            className="hover:bg-blue-700 py-0.5 px-2 rounded-lg transition">12 422 29 47</a></p>
                        <p>Email: <a href="mailto:sekretariat@zse.krakow.pl"
                            className="hover:bg-blue-700 py-0.5 px-2 rounded-lg transition">sekretariat@zse.krakow.pl</a>
                        </p>
                    </div>
                </div>
                <div className="">
                    <div className="highContrastBackground flex flex-col w-full bg-blue-900 dark:bg-blue-950 text-white p-6 space-y-6 rounded-lg shadow-lg">
                        <p className="font-semibold text-center text-lg">Zespół Szkół Energetycznych w Krakowie</p>
                        <p className="text-justify">Jesteśmy nowoczesną szkołą ponadpodstawową mieszczącą się w samym
                            centrum
                            Krakowa.
                            Kształcimy w zawodach ciągle poszukiwanych na rynku pracy.
                            ZSE zostało odznaczone nagrodą 700-lecia Lokacji Miasta Krakowa.</p>
                        <div className="flex flex-col space-y-4">
                            <p className="text-center font-semibold">Zobacz ofertę szkoły</p>
                            <iframe className="rounded-lg" height="315" src="https://www.youtube-nocookie.com/embed/BrTexzkoSPA" title="YouTube video player" frameBorder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" allowFullScreen></iframe>
                        </div>
                    </div>
                </div>
                <div className="">
                    <div className="highContrastBackground flex text-justify flex-col bg-blue-900 dark:bg-blue-950 rounded-lg p-5 text-white">
                        <p className="font-semibold text-center text-xl mb-5">Osiągnięcia</p>
                        <AchievementsBlock />
                    </div>
                </div>
                <div className="">
                    <div className="highContrastBackground flex text-justify flex-col bg-blue-900 dark:bg-blue-950 rounded-lg p-5 text-white">
                        <p className="font-semibold text-center text-xl mb-6">Nasi partnerzy</p>
                        <PartnersBlock />
                    </div>
                </div>
                <div className="">
                    <a href="http://www.bip.krakow.pl/?bip_id=264">
                        <div className="highContrastBackground flex h-20 p-5 items-center justify-between rounded-lg cursor-pointer transition duration-300 hover:shadow-xl bg-blue-900 dark:bg-blue-950 hover:bg-blue-700 hover:dark:bg-blue-900 text-white">
                            <div className="flex items-center space-x-4">
                                <img src="/assets/bip-white.png" alt="BIP" className="h-9" />
                                <p className="text-lg font-semibold lg:hidden">BIP</p>
                                <p className="hidden lg:block text-lg font-semibold">Biuletyn Informacji Publicznej</p>
                            </div>
                            <IconChevronRight />
                        </div>
                    </a>
                </div>
            </div>
            <MainArticles />
        </main>
    );
}

export default MainPage;