import { IconChevronRight } from "@tabler/icons-react";
import Article from "./Article";
import { Link } from "react-router-dom";
import { useEffect, useState } from "react";
import { getLatestPostsLimited } from "../WordPressConnectionHelper";

const MainArticles = () => {

    const [postsList, setPostsList] = useState([]);

    const getLatestArticles = () => {
        getLatestPostsLimited(7).then((data) => {
            if (data) {
                setPostsList(data.map((single) => <Article key={single.id} id={single.id} title={single.title.rendered} mainImage={single.featured_media} creationDate={single.date} content={single.excerpt.rendered} />));
            }
        });
    }

    useEffect(() => {
        getLatestArticles();
    }, []);

    return (
        <div>
            <div
                className="flex flex-col md:min-w-[400px] md:order-1 mt-1 mb-4 md:mt-5 mx-3 order-1 max-w-4xl flex-grow border-dashed md:border-solid md:rounded-lg">
                <p className="mb-10 mt-9 md:mt-0 text-center md:text-left text-3xl font-bold bg-clip-text bg-gradient-to-r text-transparent from-blue-500 to-blue-900 highContrastBackground">Aktualności</p>
                <div className="flex flex-col space-y-7 w-full flex-grow md:min-w-[400px] xl:min-w-[750px]">
                    {postsList}
                    <Link to={`archiwum`}>
                        <div
                            className="highContrastBackground flex items-center text-sm justify-center space-x-1.5 rounded-md dark:bg-gray-900 dark:text-white border border-slate-200 dark:border-gray-800 hover:underline transition cursor-pointer p-3">
                            <p>Zobacz pozostałe aktualności</p>
                            <IconChevronRight className="w-4 h-4" />
                        </div>
                    </Link>

                </div>
            </div>
        </div>
    );
}

export default MainArticles;