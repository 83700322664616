import { IconChevronRight } from "@tabler/icons-react";
import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { getMediaById } from "../WordPressConnectionHelper";

const Article = (props) => {
    const regex = /<p>(.*?)<\/p>/g;
    const navigate = useNavigate();
    const openPost = () => {
        navigate(`/post/${props.id}`);
    }
    const [mainImage, setMainImage] = useState(null);
    const [readableDate, setReadableDate] = useState(null);
    const [postFragment, setPostFragment] = useState(null);

    const postLoad = () => {
        let extractedText = regex.exec(props.content);
        if(extractedText) {
            extractedText = extractedText[1].replaceAll(/\[[^\]]*\]/g,"").replaceAll("&#8222;", "\"").replaceAll("&#8221;", "\"").replaceAll("&nbsp;", " ").trim();
            setPostFragment(extractedText);
        }

        const d = new Date(props.creationDate);
        setReadableDate(d.toLocaleDateString());

        if (props.mainImage) {
            getMediaById(props.mainImage)
                .then((data) => {
                    if(data.alt === "") {
                        data.alt = "Zdjęcie ilustrujące artykuł";
                    }
                    setMainImage(data);
                });
        }
    }

    useEffect(() => {
        postLoad();
    }, [props]);

    return (
        <div onClick={openPost}
            className="highContrastBackground text-left max-w-3xl w-full flex-grow bg-slate-50 dark:bg-gray-800 dark:text-white rounded-lg hover:shadow-xl transition hover:-translate-y-0.5 cursor-pointer duration-500 p-5 flex flex-col space-y-4 border border-slate-200 dark:border-gray-700">
            {mainImage && <img src={mainImage.url} alt={mainImage.alt} className="rounded-lg">
            </img>}
            <div className="flex flex-col space-y-2">
                <p className="text-2xl font-semibold">{props.title}</p>
                <p className="text-xs">{readableDate}</p>
            </div>

            <p className="line-clamp-3 !max-h-52">{postFragment}</p>

            <div
                className=" highContrastBackground text-sm flex space-x-1.5 items-center self-end rounded-md border border-slate-300 hover:bg-slate-200 dark:hover:bg-gray-600 dark:bg-gray-700 dark:border-gray-600 transition px-3 py-2">
                <p>Czytaj dalej</p>
                <IconChevronRight className="w-4 h-4" />
            </div>
        </div>
    );
}

export default Article;