import { useEffect, useState } from "react";
import { getAllMediaFromPostWithResoultion, getPage } from "../WordPressConnectionHelper";
import { useLocation } from "react-router-dom";
import PhotoAlbum from "react-photo-album";
import Lightbox from "yet-another-react-lightbox";
import "yet-another-react-lightbox/styles.css";
import Counter from "yet-another-react-lightbox/plugins/counter";
import "yet-another-react-lightbox/plugins/counter.css";
import Zoom from "yet-another-react-lightbox/plugins/zoom";
import Slideshow from "yet-another-react-lightbox/plugins/slideshow";

const DynamicContentPage = () => {

    const location = useLocation();

    const [title, setTitle] = useState("");
    const [content, setContent] = useState("");
    const [media, setMedia] = useState(null);
    const [index, setIndex] = useState(-1);

    const loadPage = () => {
        const pageName = window.location.pathname.split("/").pop();
        getPage(pageName)
        .then((data) => {
            setTitle(data[0].title.rendered);
            setContent(data[0].content.rendered);
            getAllMediaFromPostWithResoultion(data[0].id).then((data) => {
                if(data && data.length > 0) {
                    setMedia(data);
                }
            })
        })
    }


    useEffect(() => {
        setMedia(null);
        loadPage();

    }, [location]);

    return (
        <div>
            <div className="subpages-main-div">
                <div className={`highContrastBackground subpages-content-div ${window.location.pathname.split("/").pop() == 'dzwonki' ? 'text-center' : ''} ${window.location.pathname.split("/").pop() == 'podreczniki' ? '!max-w-none' : ''}`}>
                    <p className="subpages-p text-left">{title}</p>
                    { <div className="dont-show-images paragraph-margin iframe-content" dangerouslySetInnerHTML={{ __html: content }} />}

                    {media &&
                        <div className="round-images border dark:border-gray-800 rounded-lg px-5 py-4">
                            <PhotoAlbum
                                layout="columns"
                                photos={media}
                                onClick={({ index: current }) => setIndex(current)}
                            />
                            <Lightbox
                                index={index}
                                slides={media}
                                open={index >= 0}
                                close={() => setIndex(-1)}
                                plugins={[Counter, Zoom, Slideshow]}
                            />
                        </div>
                    }
                </div>
            </div>
        </div>
    );
}

export default DynamicContentPage;