import { IconChevronRight, IconFileText, IconNews } from "@tabler/icons-react";
import { useNavigate } from "react-router-dom";

const SearchResultItem = (props) => {
    const navigate = useNavigate();

    const itemClick = () => {
        if(props.type == "post") {
            navigate(`/post/${props.contentID}`);
        } else if(props.type == "page") {
            navigate(`/${props.contentSlug}`);
        }
    }

    return (
        <div onClick={itemClick} className="flex justify-between gap-3 w-full bg-slate-50 dark:bg-gray-800 border dark:border-gray-700 hover:bg-slate-100 hover:dark:bg-gray-700/50 transition rounded-lg px-5 py-4 cursor-pointer">
            <p className="text-xl font-semibold">{props.title}</p>
            <div className="flex items-center gap-3">
                {props.type == "post" &&
                    <div className="flex items-center gap-1.5 px-2 py-1 rounded-lg border dark:border-gray-600 w-fit">
                        <IconNews size={20} />
                        <p className="text-sm">Post</p>
                    </div>
                }
                {props.type == "page" &&
                    <div className="flex items-center gap-1.5 px-2 py-1 rounded-lg border dark:border-gray-600 w-fit">
                        <IconFileText size={20} />
                        <p className="text-sm">Strona</p>
                    </div>
                }
                <IconChevronRight />
            </div>
        </div>
    );
}

export default SearchResultItem;