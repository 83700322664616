import { IconChevronRight, IconSectionSign } from "@tabler/icons-react";
import { useEffect, useState } from "react";
import { getAllMediaFromPage, getPage } from "../WordPressConnectionHelper";

const Statut = () => {
    const [link, setLink] = useState('');

    useEffect(() => {
        getPage("statut-szkoly").then((data) => {
            getAllMediaFromPage(data[0].id)
            .then((data) => {
                setLink(data[0].guid.rendered);
            })
        });

    }, []);


    return (
        <div className="subpages-main-div">
            <div className="highContrastBackground custom-page">
                <p className="subpages-p">Statut szkoły</p>
                <div className="flex justify-center">
                    <a className="w-full" href={link}>
                        <div className="link-main-div bg-slate-100 dark:bg-gray-800 hover:bg-slate-200 hover:dark:bg-gray-800/50">
                            <div className="flex items-center space-x-4">
                                <IconSectionSign />
                                <p className="text-lg font-semibold">Statut szkoły</p>
                            </div>
                            <div className="flex space-x-2">
                                <p>Otwórz</p>
                                <IconChevronRight />
                            </div>
                        </div>
                    </a>
                </div>
            </div>
        </div>

    );
}

export default Statut;