import { useEffect, useState } from "react";
import { getAllMediaFromPage, getPage } from "../WordPressConnectionHelper";

const PartnersBlock = () => {
    const [partnersList, setPartnersList] = useState([]);

    const loadBlock = () => {
        getPage("partnerzy-szkoly")
            .then((data) => {
                if (data.length !== 0) {
                    getAllMediaFromPage(data[0].id)
                        .then((data) => {
                            setPartnersList(data.map((single) => {
                                let link = single.caption.rendered;
                                link = link.substr(3);
                                link = link.substr(0, link.length - 5);

                                let alt = single.alt_text;
                                if (alt === "") {
                                    alt = "Logo partnera ZSE";
                                }
                                return [single.guid.rendered, link, alt];
                            }));
                        })
                }
            })
    }

    useEffect(() => {
        loadBlock();
    }, []);


    return (
        <div>
            <div className="grid gap-3 grid-cols-2 md:grid-cols-3">
                {
                    partnersList.map((single) => {
                        return <a key={single[1]} href={single[1]}>
                            <div className="partner-div !p-2 !h-32 bg-white dark:brightness-90 hover:dark:brightness-100">
                                <img src={single[0]} alt={single[2]} className="w-28" />
                            </div>
                        </a>
                    })
                }
            </div>
        </div>
    );
}

export default PartnersBlock;