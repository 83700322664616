import { IconContrast, IconDisabled, IconLetterA, IconMenu2, IconPhoto } from "@tabler/icons-react";
import Navigation from "./Navigation";
import { useEffect, useState } from "react";
import { Link, useLocation } from "react-router-dom";
import { biggerText, biggestText, highContrast } from "../AccessibilityStyles";

const Header = () => {
    const [showMobileMenu, setShowMobileMenu] = useState(false);
    const [colorMode, setColorMode] = useState("light");
    const location = useLocation();

    const [contrast, setContrast] = useState(false);
    const [isBiggerText, setIsBiggerText] = useState(false);
    const [isBiggestText, setIsBiggestText] = useState(false);

    useEffect(() => {
        setShowMobileMenu(false);
    }, [location]);

    useEffect(() => {
        if (window.matchMedia && window.matchMedia('(prefers-color-scheme: dark)').matches) {
            setColorMode("dark");
        } else {
            setColorMode("light");
        }
    }, []);

    window.matchMedia('(prefers-color-scheme: dark)')
        .addEventListener('change', (event) => {
            const newColorScheme = event.matches ? "dark" : "light";
            setColorMode(newColorScheme);
        });


    const contrastClick = () => {
        if (!contrast) {
            createStyle(highContrast, "highContrast");
            setContrast(true);
        } else {
            setContrast(false);
            removeStyle("highContrast");
        }
    }

    const normalTextClick = () => {
        removeStyle("biggerText");
        removeStyle("biggestText");
        setIsBiggerText(false);
        setIsBiggestText(false);
    }

    const biggerTextClick = () => {
        if (!isBiggerText) {
            createStyle(biggerText, "biggerText");
            if (isBiggestText) {
                removeStyle("biggestText");
                setIsBiggestText(false);
            }
            setIsBiggerText(true);
        }
    }

    const biggestTextClick = () => {
        if (!isBiggestText) {
            createStyle(biggestText, "biggestText");
            if (isBiggerText) {
                removeStyle("biggerText");
                setIsBiggerText(false);
            }
            setIsBiggestText(true);
        }
    }

    const createStyle = (styleSource, styleID) => {
        var style = document.createElement('style');
        style.id = styleID;
        if (style.styleSheet) {
            style.styleSheet.cssText = styleSource;
        } else {
            style.appendChild(document.createTextNode(styleSource));
        }
        document.getElementsByTagName('head')[0].appendChild(style);
    }

    const removeStyle = (id) => {
        var hs = document.getElementsByTagName('style');
        for (var i = 0, max = hs.length; i < max; i++) {
            if (hs[i].id == id) {
                hs[i].parentNode.removeChild(hs[i]);
                return;
            }
        }
    }

    return (
        <div className="relative w-content h-content">
            <div className="p-3">
                <div className="flex w-full justify-end rounded-xl h-96 lg:h-[500px] bg-center bg-cover bg-no-repeat lg:items-end shadow-xl" style={{ backgroundImage: "url(/assets/header_image.jpg)" }}>
                    <div className="self-end lg:self-start m-2 z-10">
                        <div className="highContrastDiv flex gap-2 bg-blue-900 dark:bg-blue-950 p-2 rounded-lg items-center text-white">
                            <IconDisabled className="highContrastDiv p-0.5 text-white" />
                            <IconLetterA onClick={normalTextClick} className="highContrastBackground border rounded-full cursor-pointer p-2 w-8 h-8" />
                            <IconLetterA onClick={biggerTextClick} className="highContrastBackground border rounded-full cursor-pointer p-1.5 w-8 h-8" />
                            <IconLetterA onClick={biggestTextClick} className="highContrastBackground border rounded-full cursor-pointer p-1 w-8 h-8" />
                            <IconContrast onClick={contrastClick} className="highContrastBackground border rounded-full p-1 w-8 h-8 cursor-pointer" />
                        </div>
                    </div>
                </div>
            </div>
            <div className="w-full h-full flex absolute bottom-0 sm:top-0">
                <div className="flex flex-col fixed top-0 left-0 w-full md:w-fit z-30 p-5">
                    <div
                        className="highContrastDiv flex flex-shrink-0 h-16 lg:h-20 w-full md:w-fit px-5 bg-white/90 dark:bg-gray-800/90 dark:border-gray-700 dark:text-white border rounded-lg backdrop-blur-md text-black items-center space-x-5 lg:space-x-0 shadow-xl">
                        <IconMenu2 className="lg:hidden text-current flex-shrink-0" onClick={() => setShowMobileMenu(!showMobileMenu)} />
                        <Link to={`/`}>
                            <div className="flex w-fit items-center space-x-2 text-lg">
                                <img src="/assets/godlo_polski.png" alt="Godło RP" className="hidden sm:flex h-11" />
                                <img src="/assets/logo_krakow.jpg" alt="Logo Krakowa" className="hidden sm:flex h-11" />
                                {colorMode == "light" && <img src="/assets/zse-dark.png" alt="Logo szkoły" className="h-11" />}
                                {colorMode == "dark" && <img src="/assets/zse-light.png" alt="Logo szkoły" className="h-11" />}
                                <p className="text-lg lg:text-2xl pl-2 font-semibold">Akademia Loretańska</p>
                            </div>
                        </Link>
                    </div>
                </div>
                <div className={`z-20 w-full fixed top-[70px] lg:absolute lg:top-auto lg:bottom-3 ${showMobileMenu ? 'block' : 'hidden lg:block'}`}>
                    <Navigation />
                </div>
            </div>
        </div>



    );
}

export default Header;