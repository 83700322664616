import { useEffect, useState } from "react";
import { getAllMediaFromPage, getPage } from "../WordPressConnectionHelper";

const Partnerzy = () => {
    const [partnersList, setPartnersList] = useState([]);

    const loadBlock = () => {
        getPage("partnerzy-szkoly")
            .then((data) => {
                getAllMediaFromPage(data[0].id)
                    .then((data) => {
                        setPartnersList(data.map((single) => {
                            let link = single.caption.rendered;
                            link = link.substr(3);
                            link = link.substr(0, link.length - 5);

                            let alt = single.alt_text;
                            if (alt === "") {
                                alt = "Logo partnera ZSE";
                            }
                            return [single.guid.rendered, link, alt];
                        }));
                    })
            })
    }

    useEffect(() => {
        loadBlock();
    }, []);


    return (
        <div className="subpages-main-div">
            <div className="highContrastBackground custom-page">
                <p className="subpages-p">Partnerzy szkoły</p>
                <div className="grid gap-7 sm:grid-cols-2 md:grid-cols-3">
                    {
                        partnersList.map((single) => {
                            return <a key={single[1]} href={single[1]}>
                                <div className="partner-div bg-white dark:brightness-90 hover:dark:brightness-100">
                                    <img src={single[0]} alt={single[2]} className="w-fit" />
                                </div>
                            </a>
                        })
                    }
                </div>
            </div>
        </div>
    );
}

export default Partnerzy;