import Header from './components/Header';
import { Outlet } from 'react-router-dom';
import Footer from './components/Footer';

function App() {
  return (
    <div className="App min-h-screen flex flex-col justify-between dark:bg-gray-900 highContrastBackground">

      <Header />
      <Outlet/>
      <Footer />
    </div>
  );
}

export default App;
