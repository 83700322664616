import { IconChevronRight } from "@tabler/icons-react";

const Elearning = () => {
    return (
        <div className="subpages-main-div">
            <div className="highContrastBackground custom-page">
                <p className="subpages-p">Platforma e-learningowa</p>
                <a href="https://moodle.zse.krakow.pl/">
                    <div className="link-main-div bg-slate-100 dark:bg-gray-800 hover:bg-slate-200 hover:dark:bg-gray-800/50">
                        <div className="flex items-center space-x-4">
                            <img src="/assets/moodle.png" alt="Moodle" className="w-9" />
                            <p className="text-lg font-semibold">Moodle</p>
                        </div>
                        <div className="flex space-x-2">
                            <p>Przejdź</p>
                            <IconChevronRight />
                        </div>
                    </div>
                </a>
            </div>
        </div>
    );
}

export default Elearning;