import { IconChevronDown } from "@tabler/icons-react";
import NavigationSubItem from "./NavigationSubItem";
import { useState } from "react";
import { useEffect } from "react";

const NavigationItem = (props) => {
    const [subItems, setSubItems] = useState([]);

    useEffect(() => {
        if (props.items) {
            setSubItems(props.items.map((data) => {
                return <NavigationSubItem label={data.title} key={data.ID} slug={data.slug} />
            }));
        }
    }, [props.items]);


    const [isSubItemsVisible, setIsSubItemsVisible] = useState(false);
    return (
        <div className="relative flex flex-col" onMouseEnter={() => setIsSubItemsVisible(true)} onMouseLeave={() => setIsSubItemsVisible(false)} onClick={() => setIsSubItemsVisible(!isSubItemsVisible)}>
            <div className={`highContrastDiv flex items-center p-3 justify-between space-x-2 hover:bg-white dark:hover:bg-gray-700 rounded-lg cursor-pointer sm:!border-none ${isSubItemsVisible ? 'bg-white dark:bg-gray-700 border dark:border-gray-600' : 'bg-none border-none'}`}>
                <div className="nav-item flex items-center space-x-4 lg:space-x-2">
                    {props.icon}
                    <p>{props.label}</p>
                </div>
                <IconChevronDown className="text-current w-6 h-6 lg:w-4 lg:h-4" />
            </div>
            <div className={`relative lg:absolute lg:top-12 bg-transparent rounded-lg lg:shadow-xl overflow-hidden ${isSubItemsVisible ? 'block' : 'hidden'} ${subItems.length > 7 ? 'lg:w-[550px]' : 'lg:w-72'}`}>
                <div className={`lg:mt-5 mt-1 text-left overflow-hidden space-y-1 lg:space-y-0 bg-white dark:bg-gray-800 border dark:border-gray-700 rounded-lg ${subItems.length > 7 ? 'lg:grid lg:grid-cols-2 lg:grid-rows-6 lg:grid-flow-col' : ''}`}>
                    {subItems}
                </div>

            </div>
        </div>
    );
}

export default NavigationItem;