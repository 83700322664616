const Kontakt = () => {
    return (
        <div className="subpages-main-div">
            <div className="highContrastBackground custom-page">
                <p className="subpages-p">Kontakt</p>
                <div className="flex flex-col lg:flex-row items-center justify-between text-center">
                <iframe title="Mapa" className="rounded-lg w-full lg:w-[400px] h-80 order-2 lg:order-1" src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d2561.3181140478337!2d19.928298976948383!3d50.061603815284805!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x47165b0c0bfa98f7%3A0xb8658f9aef5ac16e!2sTechnikum%20Energetyczno%20Elektroniczne%20im.%20Tadeusza%20Ko%C5%9Bciuszki%20nr%209%20w%20Krakowie!5e0!3m2!1spl!2spl!4v1685269340895!5m2!1spl!2spl" style={{border:0}} allowfullscreen="" loading="lazy" referrerpolicy="no-referrer-when-downgrade"></iframe>
                    <div className="flex flex-col px-6 pb-6 lg:p-6 order-1 lg:order-2">
                        <p className="text-3xl font-semibold mb-5">Zespół Szkół Energetycznych <br/>w Krakowie</p>
                        <div className="text-xl">
                            <p className="mb-5 lg:mb-2">ul. Loretańska 16, 31-114 Kraków</p>
                            <p>tel./fax.: <a href="tel:124222187"
                                className="hover:bg-blue-300 dark:hover:bg-blue-700 py-0.5 px-2 rounded-lg transition">12 422 21 87</a></p>
                            <p>tel.: <a href="tel:124222947"
                                className="hover:bg-blue-300 dark:hover:bg-blue-700 py-0.5 px-2 rounded-lg transition">12 422 29 47</a></p>
                            <p className="mt-5 lg:mt-2">Email: <a href="mailto:sekretariat@zse.krakow.pl"
                                className="hover:bg-blue-300 dark:hover:bg-blue-700 py-0.5 px-2 rounded-lg transition">sekretariat@zse.krakow.pl</a>
                            </p>
                        </div>

                    </div>
                </div>
            </div>
        </div>
    );
}

export default Kontakt;