import { IconSearch } from "@tabler/icons-react";
import SearchResultItem from "../components/SearchResultItem";
import { useEffect, useState } from "react";
import { getSearchResults } from "../WordPressConnectionHelper";

const Szukaj = () => {
    const [searchItems, setSearchItems] = useState(null);
    let searchQuery = window.location.pathname.split("/").pop();

    const search = (query) => {
        getSearchResults(query)
            .then((data) => {
                setSearchItems(data.map((single) => {
                    return <SearchResultItem key={single.id} title={single.title} type={single.subtype} contentSlug={single.url.slice(0, -1).split("/").pop()} contentID={single.id} />
                }));
            });
    }

    useEffect(() => {
        search(searchQuery);

        var input = document.getElementById("searchBox");

        input.addEventListener("keypress", function (event) {
    
            if (event.key === "Enter") {
                event.preventDefault();
                search(input.value);
            }
        });
    }, []);

    return (
        <div>
            <div className="subpages-main-div">
                <div className="highContrastBackground custom-page">
                    <div className="flex flex-col gap-10">
                        <div className="flex items-center justify-center gap-4 text-3xl font-semibold">
                            <IconSearch size={35} />
                            <p>Szukaj</p>
                        </div>
                        <div className="highContrastBackground flex items-center bg-slate-100 border-slate-200 dark:bg-gray-800 dark:border-gray-700 dark:text-white px-4 py-2.5 rounded-lg gap-3 border">
                            <IconSearch size="20" />
                            <input id="searchBox" defaultValue={searchQuery} type="text" placeholder="Szukaj na stronie..." className="w-full outline-none border-none bg-transparent rounded-lg" />
                        </div>
                    </div>
                    <div className="flex flex-col my-10">
                        <p className="font-semibold mb-4">Wyniki wyszukiwania</p>
                        <div className="flex flex-col gap-4">
                            {searchItems}
                        </div>

                    </div>
                </div>
            </div>
        </div>
    );
}

export default Szukaj;