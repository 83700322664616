import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
import App from './App';
import { RouterProvider, createBrowserRouter } from 'react-router-dom';
import MainPage from './pages/MainPage';
import Kontakt from './pages/Kontakt';
import Strony from './pages/Strony';
import Partnerzy from './pages/Partnerzy';
import Statut from './pages/Statut';
import Elearning from './pages/Elearning';
import DynamicContentPage from './pages/DynamicContentPage';
import Archiwum from './pages/Archiwum';
import SinglePostPage from './pages/SinglePostPage';
import Szukaj from './pages/Szukaj';
import Sport from './pages/Sport';

const router = createBrowserRouter([
  {
    path: "/",
    element: <App />,
    children: [
      {
        path: "/",
        element: <MainPage />
      },
      {
        path: "/:pageID",
        element: <DynamicContentPage />
      },
      {
        path: "/archiwum",
        element: <Archiwum />
      },
      {
        path: "/post/:postID",
        element: <SinglePostPage />
      },
      {
        path: "/kontakt",
        element: <Kontakt />
      },
      {
        path: "/sport",
        element: <Sport />
      },
      {
        path: "/nasze-strony",
        element: <Strony />
      },
      {
        path: "/partnerzy-szkoly",
        element: <Partnerzy />
      },
      {
        path: "/statut-szkoly",
        element: <Statut />
      },
      {
        path: "/platforma-e-learningowa",
        element: <Elearning />
      },
      {
        path: "/szukaj/:query",
        element: <Szukaj />
      },
    ]
  }
]);

const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
  <React.StrictMode>
    <RouterProvider router={router} />
  </React.StrictMode>
);
