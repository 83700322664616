import { useEffect, useState } from "react";
import { getAllMediaFromPage, getPage } from "../WordPressConnectionHelper";

const AchievementsBlock = () => {
    const [mainText, setMainText] = useState("");
    const [imagesList, setImagesList] = useState([]);

    const loadBlock = () => {
        getPage("osiagniecia-strona-glowna")
            .then((data) => {
                if (data.length !== 0) {
                    setMainText(data[0].content.rendered);
                    getAllMediaFromPage(data[0].id)
                        .then((data) => {
                            setImagesList(data.map((single) => {
                                let alt = single.alt_text;
                                if(alt === "") {
                                    alt = "Tarcza Perspektyw";
                                }
                                return [single.guid.rendered, alt];
                            }));
                        })
                }
            })
    }

    useEffect(() => {
        loadBlock();
    }, []);



    return (
        <div className="flex text-justify flex-col">
            {<div className="dont-show-images paragraph-margin" dangerouslySetInnerHTML={{ __html: mainText }} />}

            <div className="flex flex-col gap-7 flex-wrap mt-5 items-center justify-center">
                <img className="h-52 rounded-lg" src={imagesList[0] ? imagesList[0][0] : ''} alt={imagesList[0] ? imagesList[0][1] : ''} />
                <div className="grid gap-3 grid-cols-5">
                    {
                        imagesList.map((single) => {
                            return <img key={single[0]} src={single[0]} alt={single[1]} className="w-24 rounded-lg" />
                        })
                    }
                </div>
            </div>
        </div>
    );
}

export default AchievementsBlock;